import { clsMerge } from "@artifactlabs/shared-react-utils";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FunctionComponent } from "react";

import SuccessTick from "@/components/Icons/SuccessTick";
import { useCloseModal } from "@/hooks/useModal";
import { poppins } from "@/utils/font";

interface Props {
  status: "success" | "error" | "warning" | "info";
  title: string;
  description: string;
  mainActionText: string;
  secondaryActionText?: string;
  onConfirm: () => void;
  onSecondaryClick?: () => void;
}

const GenericModal: FunctionComponent<Props> = ({
  status = "success",
  title,
  description,
  mainActionText,
  secondaryActionText,
  onSecondaryClick,
  onConfirm,
}) => {
  const closeModal = useCloseModal();

  return (
    <div
      className={clsMerge(
        "flex max-h-[97vh] w-[456px] flex-col items-center justify-between rounded-lg bg-white px-4 pb-6 pt-10 antialiased",
        poppins.className,
      )}
    >
      <div className="flex flex-col items-center">
        {status === "success" && <SuccessTick className="h-16 w-16 pb-10" />}
        {status === "error" && <ExclamationCircleIcon className="mb-4 h-16 w-16" fill="#C63328" />}
        <div
          className={clsMerge(
            "pb-4 text-center text-base font-bold text-[#343434]",
            poppins.className,
          )}
        >
          {title}
        </div>

        <div
          className={clsMerge(
            "text-center text-sm font-medium leading-tight text-[#343434]",
            poppins.className,
          )}
        >
          {description}
        </div>
      </div>

      <div className="mt-10 flex gap-4">
        {secondaryActionText && (
          <button
            className={clsMerge(
              "flex h-10 items-center justify-center rounded-[4px] px-4 py-4 text-sm font-medium leading-[20px]",
              poppins.className,
            )}
            onClick={() => {
              onSecondaryClick && onSecondaryClick();
              closeModal();
            }}
          >
            {secondaryActionText}
          </button>
        )}

        <button
          className={clsMerge(
            "flex h-10 items-center justify-center rounded-[4px] bg-[#1C2442] px-4 py-4 text-sm font-medium leading-[20px] text-white hover:bg-[#35447d]",
            poppins.className,
          )}
          onClick={() => {
            onConfirm && onConfirm();
            closeModal();
          }}
        >
          {mainActionText}
        </button>
      </div>
    </div>
  );
};

export default GenericModal;
