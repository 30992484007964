//
interface CurrencySymbols {
  [key: string]: string;
}

//
const currencySymbols: CurrencySymbols = {
  HKD: "HK$",
  USD: "US$",
};

//
const getCurrencySymbols = (currency: string) => {
  //
  return currencySymbols[currency] ?? "US$";
};

export { getCurrencySymbols };
