/* eslint-disable @typescript-eslint/no-restricted-imports */
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, FunctionComponent } from "react";

import { poppins } from "@/utils/font";

interface ConfirmationModalProps {
  title: string;
  description: string;
  handleSubmit: () => void;
  handleClose: () => void;

  submitButtonText: string;
  isOpen: boolean;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  handleSubmit,
  handleClose,
  title,
  description,
  submitButtonText,
  isOpen,
}) => {
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog className="absolute top-0 z-50" onClose={() => undefined}>
        <Transition.Child
          as={"button"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 z-40 bg-gradient-to-b from-[#1215244D] to-[#00000066] transition-opacity"
            role="button"
            onClick={handleClose}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-50 m-auto  mx-auto h-fit w-fit overflow-y-auto overflow-x-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative w-full min-w-[424px] max-w-sm transform overflow-hidden rounded-lg bg-white px-4 pb-6 pt-10 text-left shadow-xl transition-all">
              <div className="font-poppins antialiased">
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="font text-center text-base font-bold text-[#343434]"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-center text-sm font-medium leading-tight text-[#343434]">
                      {description}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={clsMerge(
                  "mt-10 flex items-center justify-center gap-x-10",
                  poppins.className,
                )}
              >
                <button
                  className="inline-flex h-10 flex-col items-center justify-center px-4 focus:outline-none"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="outline:border-[#1C2442] inline-flex h-10 flex-col items-center justify-center rounded border bg-[#1C2442] px-4 text-white"
                  type="button"
                  onClick={handleSubmit}
                >
                  {submitButtonText}
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
