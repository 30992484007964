"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTypeToReadableMapping = void 0;
exports.useTypeToReadableMapping = {
    publication: "Publication",
    "education/research": "Education/Research",
    digital: "Digital",
    broadcast: "Broadcast",
    exhibition: "Exhibition",
    "government/public_policy": "Government/Public policy",
    personal: "Personal",
    other: "Other",
    commercial_exhibition: "Commercial - exhibition",
    non_profit_exhibition: "Non-profit - exhibition",
    commercial_above_the_line: "Commercial - Above the line",
    commercial_digital: "Commercial - digital",
    commercial_internal_use: "Commercial - internal use",
    commercial_marketing_collaterals: "Commercial - marketing collaterals",
    commercial_print: "Commercial - print",
    commercial_print_digital: "Commercial - print + digital",
    course_material_digital: "Course material - digital",
    course_material_print: "Course material - print",
    course_material_print_digital: "Course material - print + digital",
    documentary: "Documentary",
    editorial_use_broadcast: "Editorial use - Broadcast",
    editorial_use_digital: "Editorial use - digital",
    editorial_use_print: "Editorial use - print",
    editorial_use_print_digital: "Editorial use - print + digital",
    non_profit_digital: "Non-profit - digital",
    non_profit_internal_use: "Non-profit - internal use",
    non_profit_print: "Non-profit - print",
    non_profit_print_digital: "Non-profit - print + digital",
    other_educational_research: "Other Educational / Research",
    commercial: "Commercial",
    editorial: "Editorial",
    custom: "Custom",
    donation: "Donation",
};
