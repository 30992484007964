import { clsMerge } from "@artifactlabs/shared-react-utils";
import { motion } from "framer-motion";
import Link from "next/link";
import { FunctionComponent, HTMLAttributes, useId } from "react";

interface Tab {
  name: string;
  href?: any;
  key?: string;
  current: boolean;
}
interface Props extends HTMLAttributes<HTMLDivElement> {
  tabs: Array<Tab>;
  onTabClick?: (tab: Tab) => void;
}

const HorizontalTab: FunctionComponent<Props> = ({ tabs, onTabClick, ...rest }) => {
  const id = useId();

  return (
    <div {...rest}>
      <div className="sm:hidden">
        <label className="sr-only" htmlFor="tabs">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find(tab => tab.current)?.name || ""}
          id="tabs"
          name="tabs"
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div>
          <nav aria-label="Tabs" className="-mb-px flex space-x-6">
            {tabs.map(tab => {
              if (tab.href) {
                return (
                  <Link
                    aria-current={tab.current ? "page" : undefined}
                    className={clsMerge(
                      "relative flex justify-center whitespace-nowrap py-4 text-sm font-medium leading-[20px] text-black",
                      !tab.current && "text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    )}
                    href={tab.href}
                    key={tab.name}
                  >
                    {tab.current && (
                      <motion.div
                        className="absolute bottom-[0px] h-[2px] w-full rounded-[4px] bg-[#B4171E]"
                        layoutId={`tab.menu.rounded.border.${id}`}
                      />
                    )}
                    {tab.name}
                  </Link>
                );
              }

              return (
                <div
                  aria-current={tab.current ? "page" : undefined}
                  className={clsMerge(
                    "relative flex cursor-pointer justify-center whitespace-nowrap py-4 text-sm font-medium leading-[20px] text-black",
                    !tab.current && "text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  )}
                  key={tab.name}
                  {...(onTabClick ? { onClick: () => onTabClick(tab) } : {})}
                >
                  {tab.current && (
                    <motion.div
                      className="absolute bottom-[0px] h-[2px] w-full rounded-[4px] bg-[#B4171E]"
                      layoutId={`tab.menu.rounded.border.${id}`}
                    />
                  )}
                  {tab.name}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HorizontalTab;
