/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date scalar */
  Date: { input: any; output: any };
  RateCardOptionValue: { input: any; output: any };
};

export type AdminAddCollectionToUserInput = {
  collectionId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  orgId: Scalars["String"]["input"];
};

export type AdminCreateUserInput = {
  email: Scalars["String"]["input"];
  organizationProfile: OrganizationProfileInput;
  password: Scalars["String"]["input"];
  profile: ProfileInput;
};

export type AllFacet = {
  __typename?: "AllFacet";
  assetTypeFacet?: Maybe<Facet>;
  creationDateFacet?: Maybe<Facet>;
  creatorNameFacet?: Maybe<Facet>;
  exhibitionLoanHistoryFacet?: Maybe<Facet>;
  materialsAndTechnologyFacet?: Maybe<Facet>;
  orientationFacet?: Maybe<Facet>;
  relevantDatesFacet?: Maybe<Facet>;
  relevantGeosFacet?: Maybe<Facet>;
  seriesNameFacet?: Maybe<Facet>;
  copyrightOrRestrictionsFacet?: Maybe<Facet>;
};

export type Artwork = {
  __typename?: "Artwork";
  assets: Array<ArtworkAsset>;
  digitalTwin?: Maybe<ArtworkDigitalTwin>;
  extId: Scalars["String"]["output"];
  importRef?: Maybe<ArtworkImportRef>;
  importerVersion?: Maybe<Scalars["String"]["output"]>;
  licenseOptions?: Maybe<Array<Maybe<ArtworkLicenseOptions>>>;
  metadata?: Maybe<ArtworkMetadata>;
  orgId: Scalars["String"]["output"];
  pricesFrom: ArtworkPricesFrom;
  rateCard: RateCard;
  src: Scalars["String"]["output"];
};

export type ArtworkPricesFromArgs = {
  currency: Scalars["String"]["input"];
};

export type ArtworkRateCardArgs = {
  currency: Scalars["String"]["input"];
};

export type ArtworkAsset = {
  __typename?: "ArtworkAsset";
  dpi?: Maybe<Scalars["Int"]["output"]>;
  fileSize?: Maybe<Scalars["Int"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  size?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type ArtworkDigitalTwin = {
  __typename?: "ArtworkDigitalTwin";
  contractAddress?: Maybe<Scalars["String"]["output"]>;
  ipfsAddress?: Maybe<Scalars["String"]["output"]>;
  tokenID?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkDimension = {
  __typename?: "ArtworkDimension";
  depth?: Maybe<Scalars["Float"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  unit?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

export type ArtworkFilter = {
  path: Scalars["String"]["input"];
  query: Scalars["String"]["input"];
};

export type ArtworkImportRef = {
  __typename?: "ArtworkImportRef";
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkLicenseOptions = {
  __typename?: "ArtworkLicenseOptions";
  currency?: Maybe<Scalars["String"]["output"]>;
  defaultLicenseOption?: Maybe<Scalars["Boolean"]["output"]>;
  licenseMode?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  rateCard?: Maybe<ArtworkLicenseRateCard>;
};

export type ArtworkLicenseRateCard = {
  __typename?: "ArtworkLicenseRateCard";
  contentType?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["Float"]["output"]>;
  licenseType?: Maybe<Scalars["String"]["output"]>;
  resolution?: Maybe<Scalars["String"]["output"]>;
  territory?: Maybe<Scalars["String"]["output"]>;
  usage?: Maybe<Scalars["String"]["output"]>;
  use?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkMetadata = {
  __typename?: "ArtworkMetadata";
  assetFileFormat?: Maybe<Scalars["String"]["output"]>;
  assetFullText?: Maybe<Scalars["String"]["output"]>;
  assetType?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  assetURL?: Maybe<Scalars["String"]["output"]>;
  copyrightDocument?: Maybe<Scalars["String"]["output"]>;
  copyrightHolder?: Maybe<Scalars["String"]["output"]>;
  copyrightOrRestrictions?: Maybe<Scalars["String"]["output"]>;
  creationDate?: Maybe<Scalars["String"]["output"]>;
  creatorName?: Maybe<Scalars["String"]["output"]>;
  creditLine?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dimension?: Maybe<ArtworkDimension>;
  exhibitionLoanHistory?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  isPhysicalAsset?: Maybe<Scalars["Boolean"]["output"]>;
  materialsAndTechnology?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name: Scalars["String"]["output"];
  provenanceDocument?: Maybe<Scalars["String"]["output"]>;
  relevantDates?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  relevantGeos?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  seriesName?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkPricesFrom = {
  __typename?: "ArtworkPricesFrom";
  commercial?: Maybe<Scalars["Float"]["output"]>;
  custom?: Maybe<Scalars["Float"]["output"]>;
  editorial?: Maybe<Scalars["Float"]["output"]>;
  personal?: Maybe<Scalars["Float"]["output"]>;
};

export type ArtworkQueryExactFilters = {
  andFilter?: InputMaybe<Array<InputMaybe<ArtworkFilter>>>;
  filter?: InputMaybe<Array<InputMaybe<ArtworkFilter>>>;
  filters?: InputMaybe<Array<InputMaybe<Array<InputMaybe<ArtworkFilter>>>>>;
};

export type ArtworkQueryOptionsInput = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtworkSearchFacets = {
  __typename?: "ArtworkSearchFacets";
  count?: Maybe<Count>;
  facet?: Maybe<AllFacet>;
};

export type Auth = {
  __typename?: "Auth";
  email?: Maybe<Scalars["String"]["output"]>;
};

export type Bucket = {
  __typename?: "Bucket";
  _id?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export enum Checkout_Mode {
  DirectPurchase = "DIRECT_PURCHASE",
  RequestPurchase = "REQUEST_PURCHASE",
}

export type CheckoutFlow = {
  __typename?: "CheckoutFlow";
  mode: Checkout_Mode;
};

export type CheckoutOptions = {
  __typename?: "CheckoutOptions";
  stripe: StripeCheckoutOptions;
};

export type CheckoutSessionResponse = {
  __typename?: "CheckoutSessionResponse";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
};

export type Count = {
  __typename?: "Count";
  lowerBound?: Maybe<Scalars["Int"]["output"]>;
};

export type CreatePrefilledLicenseInput = {
  conditions: RequestConditionsInput;
  details: RequestDetailsInput;
  extId?: InputMaybe<Scalars["String"]["input"]>;
  makeDefault: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export type Entity = {
  __typename?: "Entity";
  addressLineOne?: Maybe<Scalars["String"]["output"]>;
  addressLineTwo?: Maybe<Scalars["String"]["output"]>;
  contactNumber?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  industry?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type EntityInput = {
  addressLineOne?: InputMaybe<Scalars["String"]["input"]>;
  addressLineTwo?: InputMaybe<Scalars["String"]["input"]>;
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  industry?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Facet = {
  __typename?: "Facet";
  buckets?: Maybe<Array<Maybe<Bucket>>>;
};

export type FeatureState = {
  __typename?: "FeatureState";
  checkoutFlow: CheckoutFlow;
  checkoutOptions: CheckoutOptions;
  licenseOptions: LicenseOptions;
  showLicenseTooltip: ShowLicenseTooltip;
};

export type GetArtworkAssetFromVaultResponse = {
  __typename?: "GetArtworkAssetFromVaultResponse";
  downloadUrl?: Maybe<Scalars["String"]["output"]>;
};

export type Invalid = {
  __typename?: "Invalid";
  reason: Scalars["String"]["output"];
  timestamp: Scalars["Date"]["output"];
};

export type License = {
  __typename?: "License";
  conditions: LicenseCondition;
  currency: Scalars["String"]["output"];
  details: LicenseDetails;
};

export type LicenseCondition = {
  __typename?: "LicenseCondition";
  contentType?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Float"]["output"];
  resolution: Scalars["String"]["output"];
  territory: Scalars["String"]["output"];
  usage: Scalars["String"]["output"];
  use: Scalars["String"]["output"];
};

export type LicenseConditionInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["Float"]["input"];
  licenseType: Scalars["String"]["input"];
  resolution: Scalars["String"]["input"];
  territory: Scalars["String"]["input"];
  usage: Scalars["String"]["input"];
  use: Scalars["String"]["input"];
};

export type LicenseDetails = {
  __typename?: "LicenseDetails";
  creator: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  formatOfReproduction: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  printRun?: Maybe<Scalars["String"]["output"]>;
  publisher: Scalars["String"]["output"];
  remark?: Maybe<Scalars["String"]["output"]>;
  retailPrice?: Maybe<Scalars["Float"]["output"]>;
  title: Scalars["String"]["output"];
  useOfImage: Scalars["String"]["output"];
};

export type LicenseDetailsInput = {
  creator: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  formatOfReproduction: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  printRun?: InputMaybe<Scalars["String"]["input"]>;
  publisher: Scalars["String"]["input"];
  remark?: InputMaybe<Scalars["String"]["input"]>;
  retailPrice?: InputMaybe<Scalars["Float"]["input"]>;
  title: Scalars["String"]["input"];
  useOfImage: Scalars["String"]["input"];
};

export type LicenseInput = {
  conditions: LicenseConditionInput;
  currency: Scalars["String"]["input"];
  details: LicenseDetailsInput;
};

export type LicenseOption = {
  __typename?: "LicenseOption";
  defualt?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  mode: Options_Mode;
  template?: Maybe<Scalars["String"]["output"]>;
};

export type LicenseOptions = {
  __typename?: "LicenseOptions";
  commercial: LicenseOption;
  custom: LicenseOption;
  editorial: LicenseOption;
  personal: LicenseOption;
};

export enum LimitedUpdateRequestStatus {
  Accepted = "ACCEPTED",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Revoked = "REVOKED",
}

export type Mutation = {
  __typename?: "Mutation";
  addCollectionToUser: Response;
  checkoutVault: Array<RequestCreation>;
  completeMultiRequestsProcessing?: Maybe<Array<Maybe<Request>>>;
  completeStraightThroughRequestByExtId?: Maybe<Request>;
  createCheckoutSession?: Maybe<CheckoutSessionResponse>;
  createRequest: RequestCreation;
  createUser: Response;
  createVault?: Maybe<Vault>;
  createVaultItem?: Maybe<Vault>;
  deletePrefilledLicense?: Maybe<Scalars["Boolean"]["output"]>;
  prefilledLicense?: Maybe<PrefilledLicense>;
  removeVaultItem?: Maybe<Vault>;
  updateRequestsStatus?: Maybe<RequestUpdate>;
  updateVaultItem?: Maybe<Vault>;
  user?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MutationAddCollectionToUserArgs = {
  input: AdminAddCollectionToUserInput;
};

export type MutationCheckoutVaultArgs = {
  vaultItemExtIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationCompleteMultiRequestsProcessingArgs = {
  extIds: Array<Scalars["String"]["input"]>;
};

export type MutationCompleteStraightThroughRequestByExtIdArgs = {
  extId: Scalars["String"]["input"];
};

export type MutationCreateCheckoutSessionArgs = {
  currency: Scalars["String"]["input"];
  extIds: Array<InputMaybe<Scalars["String"]["input"]>>;
  orderKey: Scalars["String"]["input"];
  unitAmount: Scalars["Int"]["input"];
};

export type MutationCreateRequestArgs = {
  artwork: Scalars["String"]["input"];
  currency: Scalars["String"]["input"];
  request: RequestInput;
};

export type MutationCreateUserArgs = {
  input: AdminCreateUserInput;
};

export type MutationCreateVaultItemArgs = {
  item?: InputMaybe<VaultItemInput>;
  withCreatePrefilledLicense?: InputMaybe<WithCreatePrefilledLicenseInput>;
};

export type MutationDeletePrefilledLicenseArgs = {
  extId: Scalars["String"]["input"];
};

export type MutationPrefilledLicenseArgs = {
  input?: InputMaybe<CreatePrefilledLicenseInput>;
};

export type MutationRemoveVaultItemArgs = {
  extId: Scalars["String"]["input"];
};

export type MutationUpdateRequestsStatusArgs = {
  requestStatus: LimitedUpdateRequestStatus;
  requests: Array<RequestWithVersionInput>;
};

export type MutationUpdateVaultItemArgs = {
  extId?: InputMaybe<Scalars["String"]["input"]>;
  item?: InputMaybe<VaultItemInput>;
  withCreatePrefilledLicense?: InputMaybe<WithCreatePrefilledLicenseInput>;
};

export type MutationUserArgs = {
  input?: InputMaybe<UserCreateUserInput>;
};

export enum Options_Mode {
  Email = "EMAIL",
  Purchase = "PURCHASE",
  Request = "REQUEST",
}

export type OrganizationProfileInput = {
  isAdmin: Scalars["Boolean"]["input"];
  orgId: Scalars["String"]["input"];
};

export type PaginatedArtwork = {
  __typename?: "PaginatedArtwork";
  paginateMeta?: Maybe<PaginatedMetadata>;
  paginatedResults?: Maybe<Array<Maybe<Artwork>>>;
};

export type PaginatedMetadata = {
  __typename?: "PaginatedMetadata";
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type PaginatedPrefilledLicenses = {
  __typename?: "PaginatedPrefilledLicenses";
  paginateMeta: PaginatedMetadata;
  paginatedResults: Array<PrefilledLicense>;
};

export type PaginatedRequests = {
  __typename?: "PaginatedRequests";
  paginateMeta: PaginatedMetadata;
  paginatedResults: Array<Request>;
};

export type PrefilledLicense = {
  __typename?: "PrefilledLicense";
  conditions: RequestConditions;
  createdAt: Scalars["Date"]["output"];
  details: RequestDetails;
  extId: Scalars["String"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  orgId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PricingCondition = {
  __typename?: "PricingCondition";
  contentType?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Float"]["output"];
  resolution: Scalars["String"]["output"];
  territory: Scalars["String"]["output"];
  usage: Scalars["String"]["output"];
  use: Scalars["String"]["output"];
};

export type PricingConditionInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  licenseType?: InputMaybe<Scalars["String"]["input"]>;
  resolution?: InputMaybe<Scalars["String"]["input"]>;
  territory?: InputMaybe<Scalars["String"]["input"]>;
  usage?: InputMaybe<Scalars["String"]["input"]>;
  use?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProfileInput = {
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  entity?: InputMaybe<EntityInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type PublicUserProfile = {
  __typename?: "PublicUserProfile";
  auth?: Maybe<Auth>;
  extId?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<UserProfile>;
};

export type Query = {
  __typename?: "Query";
  artwork?: Maybe<Artwork>;
  artworks?: Maybe<PaginatedArtwork>;
  completedRequests: Array<Maybe<Request>>;
  featureFlags: FeatureState;
  getArtworkAssetFromVault?: Maybe<GetArtworkAssetFromVaultResponse>;
  getArtworkAutocompleteResults?: Maybe<Array<Maybe<Artwork>>>;
  getCheckoutSession?: Maybe<SessionResponse>;
  getSearchFacets?: Maybe<ArtworkSearchFacets>;
  hello?: Maybe<Response>;
  prefilledLicenses: PaginatedPrefilledLicenses;
  rateCard?: Maybe<RateCard>;
  rateCardFieldsAndPrices?: Maybe<RateCardFieldsAndPricesResponse>;
  request?: Maybe<Request>;
  requestByUser?: Maybe<Request>;
  requests?: Maybe<PaginatedRequests>;
  requestsByUser?: Maybe<PaginatedRequests>;
  searchArtworks?: Maybe<PaginatedArtwork>;
  user?: Maybe<User>;
  vault?: Maybe<Vault>;
  vaultItem?: Maybe<VaultItem>;
};

export type QueryArtworkArgs = {
  extId: Scalars["String"]["input"];
};

export type QueryCompletedRequestsArgs = {
  expired: Scalars["Boolean"]["input"];
};

export type QueryGetArtworkAssetFromVaultArgs = {
  requestExtId: Scalars["String"]["input"];
};

export type QueryGetArtworkAutocompleteResultsArgs = {
  options?: InputMaybe<ArtworkQueryOptionsInput>;
  query: Scalars["String"]["input"];
};

export type QueryGetCheckoutSessionArgs = {
  sessionId: Scalars["String"]["input"];
};

export type QueryGetSearchFacetsArgs = {
  query: Scalars["String"]["input"];
};

export type QueryRateCardArgs = {
  orgId: Scalars["String"]["input"];
};

export type QueryRateCardFieldsAndPricesArgs = {
  conditions?: InputMaybe<PricingConditionInput>;
  orgId: Scalars["String"]["input"];
  queryFor: Scalars["String"]["input"];
};

export type QueryRequestArgs = {
  extId: Scalars["String"]["input"];
};

export type QueryRequestByUserArgs = {
  extId: Scalars["String"]["input"];
};

export type QueryRequestsArgs = {
  options?: InputMaybe<RequestsQueryOptionsInput>;
  status: Scalars["String"]["input"];
};

export type QueryRequestsByUserArgs = {
  options?: InputMaybe<RequestsQueryOptionsInput>;
  status?: InputMaybe<RequestStatus>;
};

export type QuerySearchArtworksArgs = {
  exactFilters?: InputMaybe<ArtworkQueryExactFilters>;
  keyword?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<ArtworkQueryOptionsInput>;
};

export type QueryVaultItemArgs = {
  extId: Scalars["String"]["input"];
};

export type RateCard = {
  __typename?: "RateCard";
  currency: Scalars["String"]["output"];
  extId?: Maybe<Scalars["String"]["output"]>;
  rates: Array<Maybe<RateCardRule>>;
};

export type RateCardFieldsAndPricesResponse = {
  __typename?: "RateCardFieldsAndPricesResponse";
  foundPrice?: Maybe<Scalars["Int"]["output"]>;
  matchOptions?: Maybe<Array<Maybe<RateCardOptions>>>;
  maxPrice?: Maybe<Scalars["Int"]["output"]>;
  minPrice?: Maybe<Scalars["Int"]["output"]>;
};

export type RateCardOptions = {
  __typename?: "RateCardOptions";
  label?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["RateCardOptionValue"]["output"]>;
};

export type RateCardRule = {
  __typename?: "RateCardRule";
  conditions: PricingCondition;
  price: Scalars["Float"]["output"];
};

export type Request = {
  __typename?: "Request";
  artwork?: Maybe<Artwork>;
  conditions?: Maybe<RequestConditions>;
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  creatorProfile?: Maybe<UserProfile>;
  currency?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<RequestDetails>;
  expiresAt?: Maybe<Scalars["Date"]["output"]>;
  extId?: Maybe<Scalars["String"]["output"]>;
  isPaid: Scalars["Boolean"]["output"];
  price?: Maybe<Scalars["Float"]["output"]>;
  publicUserProfile?: Maybe<PublicUserProfile>;
  status?: Maybe<RequestStatus>;
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type RequestConditions = {
  __typename?: "RequestConditions";
  duration: Scalars["Float"]["output"];
  resolution: Scalars["String"]["output"];
  territory: Scalars["String"]["output"];
  usage: Scalars["String"]["output"];
  use: Scalars["String"]["output"];
};

export type RequestConditionsInput = {
  duration: Scalars["Float"]["input"];
  licenseType?: InputMaybe<Scalars["String"]["input"]>;
  resolution: Scalars["String"]["input"];
  territory: Scalars["String"]["input"];
  usage: Scalars["String"]["input"];
  use: Scalars["String"]["input"];
};

export type RequestCreatePrefilledLicenseInput = {
  create: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export type RequestCreation = {
  __typename?: "RequestCreation";
  extId: Scalars["String"]["output"];
  status: RequestStatus;
};

export type RequestDetails = {
  __typename?: "RequestDetails";
  creator: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  formatOfReproduction: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  printRun?: Maybe<Scalars["String"]["output"]>;
  publisher: Scalars["String"]["output"];
  remark?: Maybe<Scalars["String"]["output"]>;
  retailPrice?: Maybe<Scalars["Float"]["output"]>;
  title: Scalars["String"]["output"];
  useOfImage: Scalars["String"]["output"];
};

export type RequestDetailsInput = {
  creator: Scalars["String"]["input"];
  formatOfReproduction: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  printRun?: InputMaybe<Scalars["String"]["input"]>;
  publisher: Scalars["String"]["input"];
  remark?: InputMaybe<Scalars["String"]["input"]>;
  retailPrice?: InputMaybe<Scalars["Float"]["input"]>;
  title: Scalars["String"]["input"];
  useOfImage: Scalars["String"]["input"];
};

export type RequestInput = {
  conditions: RequestConditionsInput;
  details: RequestDetailsInput;
  prefilledLicense?: InputMaybe<RequestCreatePrefilledLicenseInput>;
};

export enum RequestStatus {
  Accepted = "ACCEPTED",
  Approved = "APPROVED",
  Completed = "COMPLETED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Revoked = "REVOKED",
}

export type RequestUpdate = {
  __typename?: "RequestUpdate";
  requests?: Maybe<Array<Maybe<Request>>>;
  success: Scalars["Boolean"]["output"];
};

export type RequestWithVersionInput = {
  extId: Scalars["String"]["input"];
  version: Scalars["Int"]["input"];
};

export type RequestsQueryOptionsInput = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Response = {
  __typename?: "Response";
  success: Scalars["String"]["output"];
};

export type SessionResponse = {
  __typename?: "SessionResponse";
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type ShowLicenseTooltip = {
  __typename?: "ShowLicenseTooltip";
  enabled: Scalars["Boolean"]["output"];
};

export type StripeCheckoutOptions = {
  __typename?: "StripeCheckoutOptions";
  enabled: Scalars["Boolean"]["output"];
  publishableKey?: Maybe<Scalars["String"]["output"]>;
};

export type User = {
  __typename?: "User";
  isAdmin?: Maybe<Scalars["Boolean"]["output"]>;
  orgId?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<UserProfile>;
};

export type UserCreateUserInput = {
  profile: UserProfileInput;
};

export type UserEntityMetadata = {
  __typename?: "UserEntityMetadata";
  addressLineOne?: Maybe<Scalars["String"]["output"]>;
  addressLineTwo?: Maybe<Scalars["String"]["output"]>;
  contactNumber?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  industry?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type UserEntityMetadataInput = {
  addressLineOne?: InputMaybe<Scalars["String"]["input"]>;
  addressLineTwo?: InputMaybe<Scalars["String"]["input"]>;
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  industry?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  contactNumber?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<UserEntityMetadata>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  userTier?: Maybe<UserTier>;
};

export type UserProfileInput = {
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  entity?: InputMaybe<UserEntityMetadataInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserTier {
  AllAccess = "ALL_ACCESS",
  Basic = "BASIC",
}

export type Vault = {
  __typename?: "Vault";
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  items: Array<Maybe<VaultItem>>;
  orgId: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
  userId: Scalars["String"]["output"];
};

export type VaultItem = {
  __typename?: "VaultItem";
  artwork?: Maybe<Artwork>;
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  extId: Scalars["String"]["output"];
  invalid?: Maybe<Invalid>;
  itemDetails: License;
  price: Scalars["Float"]["output"];
  refId: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type VaultItemInput = {
  itemDetails: LicenseInput;
  refId: Scalars["String"]["input"];
};

export type WithCreatePrefilledLicenseInput = {
  create: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export enum AuthProvider {
  Auth0 = "AUTH0",
  Custom = "CUSTOM",
}

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; isAdmin?: boolean | null } | null;
};

export type GetRequestDetailsQueryVariables = Exact<{
  requestExtId: Scalars["String"]["input"];
}>;

export type GetRequestDetailsQuery = {
  __typename?: "Query";
  request?: {
    __typename?: "Request";
    extId?: string | null;
    version: number;
    status?: RequestStatus | null;
    price?: number | null;
    artwork?: {
      __typename?: "Artwork";
      extId: string;
      src: string;
      assets: Array<{
        __typename?: "ArtworkAsset";
        type?: string | null;
        size?: string | null;
        width?: number | null;
        height?: number | null;
        dpi?: number | null;
        fileSize?: number | null;
      }>;
      metadata?: {
        __typename?: "ArtworkMetadata";
        name: string;
        creationDate?: string | null;
        creatorName?: string | null;
        copyrightOrRestrictions?: string | null;
        materialsAndTechnology?: Array<string | null> | null;
        seriesName?: string | null;
        relevantDates?: Array<string | null> | null;
        relevantGeos?: Array<string | null> | null;
      } | null;
      importRef?: { __typename?: "ArtworkImportRef"; value?: string | null } | null;
    } | null;
    conditions?: {
      __typename?: "RequestConditions";
      use: string;
      territory: string;
      usage: string;
      duration: number;
      resolution: string;
    } | null;
    details?: {
      __typename?: "RequestDetails";
      title: string;
      creator: string;
      formatOfReproduction: string;
      language: string;
      useOfImage: string;
      publisher: string;
      printRun?: string | null;
      retailPrice?: number | null;
      remark?: string | null;
    } | null;
    publicUserProfile?: {
      __typename?: "PublicUserProfile";
      auth?: { __typename?: "Auth"; email?: string | null } | null;
    } | null;
    creatorProfile?: {
      __typename?: "UserProfile";
      firstName?: string | null;
      lastName?: string | null;
      contactNumber?: string | null;
      userTier?: UserTier | null;
      entity?: {
        __typename?: "UserEntityMetadata";
        name?: string | null;
        jobTitle?: string | null;
        industry?: string | null;
        country?: string | null;
        addressLineOne?: string | null;
        addressLineTwo?: string | null;
        contactNumber?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateRequestsStatusMutationVariables = Exact<{
  requests: Array<RequestWithVersionInput> | RequestWithVersionInput;
  requestStatus: LimitedUpdateRequestStatus;
}>;

export type UpdateRequestsStatusMutation = {
  __typename?: "Mutation";
  updateRequestsStatus?: { __typename?: "RequestUpdate"; success: boolean } | null;
};

export type GetRequestByStateQueryVariables = Exact<{
  status: Scalars["String"]["input"];
  options?: InputMaybe<RequestsQueryOptionsInput>;
}>;

export type GetRequestByStateQuery = {
  __typename?: "Query";
  requests?: {
    __typename?: "PaginatedRequests";
    paginatedResults: Array<{
      __typename?: "Request";
      extId?: string | null;
      version: number;
      price?: number | null;
      createdAt?: any | null;
      conditions?: {
        __typename?: "RequestConditions";
        use: string;
        territory: string;
        usage: string;
        duration: number;
        resolution: string;
      } | null;
      details?: {
        __typename?: "RequestDetails";
        title: string;
        creator: string;
        formatOfReproduction: string;
        language: string;
        useOfImage: string;
        publisher: string;
        printRun?: string | null;
        retailPrice?: number | null;
        remark?: string | null;
      } | null;
      artwork?: {
        __typename?: "Artwork";
        extId: string;
        src: string;
        metadata?: {
          __typename?: "ArtworkMetadata";
          name: string;
          creationDate?: string | null;
          creatorName?: string | null;
          assetFileFormat?: string | null;
        } | null;
        importRef?: {
          __typename?: "ArtworkImportRef";
          name?: string | null;
          value?: string | null;
        } | null;
        assets: Array<{
          __typename?: "ArtworkAsset";
          size?: string | null;
          width?: number | null;
          height?: number | null;
          dpi?: number | null;
          fileSize?: number | null;
        }>;
      } | null;
      creatorProfile?: {
        __typename?: "UserProfile";
        firstName?: string | null;
        lastName?: string | null;
        userTier?: UserTier | null;
        entity?: { __typename?: "UserEntityMetadata"; name?: string | null } | null;
      } | null;
    }>;
    paginateMeta: { __typename?: "PaginatedMetadata"; totalCount?: number | null };
  } | null;
};

export const GetUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isAdmin" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetRequestDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getRequestDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "requestExtId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "request" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "extId" },
                value: { kind: "Variable", name: { kind: "Name", value: "requestExtId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "extId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "artwork" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "extId" } },
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "size" } },
                            { kind: "Field", name: { kind: "Name", value: "width" } },
                            { kind: "Field", name: { kind: "Name", value: "height" } },
                            { kind: "Field", name: { kind: "Name", value: "dpi" } },
                            { kind: "Field", name: { kind: "Name", value: "fileSize" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "creationDate" } },
                            { kind: "Field", name: { kind: "Name", value: "creatorName" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "copyrightOrRestrictions" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "materialsAndTechnology" },
                            },
                            { kind: "Field", name: { kind: "Name", value: "seriesName" } },
                            { kind: "Field", name: { kind: "Name", value: "relevantDates" } },
                            { kind: "Field", name: { kind: "Name", value: "relevantGeos" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "importRef" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "use" } },
                      { kind: "Field", name: { kind: "Name", value: "territory" } },
                      { kind: "Field", name: { kind: "Name", value: "usage" } },
                      { kind: "Field", name: { kind: "Name", value: "duration" } },
                      { kind: "Field", name: { kind: "Name", value: "resolution" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "details" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "creator" } },
                      { kind: "Field", name: { kind: "Name", value: "formatOfReproduction" } },
                      { kind: "Field", name: { kind: "Name", value: "language" } },
                      { kind: "Field", name: { kind: "Name", value: "useOfImage" } },
                      { kind: "Field", name: { kind: "Name", value: "publisher" } },
                      { kind: "Field", name: { kind: "Name", value: "printRun" } },
                      { kind: "Field", name: { kind: "Name", value: "retailPrice" } },
                      { kind: "Field", name: { kind: "Name", value: "remark" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "publicUserProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "auth" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creatorProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "contactNumber" } },
                      { kind: "Field", name: { kind: "Name", value: "userTier" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "industry" } },
                            { kind: "Field", name: { kind: "Name", value: "country" } },
                            { kind: "Field", name: { kind: "Name", value: "addressLineOne" } },
                            { kind: "Field", name: { kind: "Name", value: "addressLineTwo" } },
                            { kind: "Field", name: { kind: "Name", value: "contactNumber" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRequestDetailsQuery, GetRequestDetailsQueryVariables>;
export const UpdateRequestsStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateRequestsStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "requests" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "RequestWithVersionInput" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "requestStatus" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LimitedUpdateRequestStatus" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRequestsStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestStatus" },
                value: { kind: "Variable", name: { kind: "Name", value: "requestStatus" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "requests" },
                value: { kind: "Variable", name: { kind: "Name", value: "requests" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRequestsStatusMutation, UpdateRequestsStatusMutationVariables>;
export const GetRequestByStateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getRequestByState" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "status" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "options" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "RequestsQueryOptionsInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requests" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: { kind: "Variable", name: { kind: "Name", value: "status" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "options" },
                value: { kind: "Variable", name: { kind: "Name", value: "options" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginatedResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "extId" } },
                      { kind: "Field", name: { kind: "Name", value: "version" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "use" } },
                            { kind: "Field", name: { kind: "Name", value: "territory" } },
                            { kind: "Field", name: { kind: "Name", value: "usage" } },
                            { kind: "Field", name: { kind: "Name", value: "duration" } },
                            { kind: "Field", name: { kind: "Name", value: "resolution" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "details" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "creator" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "formatOfReproduction" },
                            },
                            { kind: "Field", name: { kind: "Name", value: "language" } },
                            { kind: "Field", name: { kind: "Name", value: "useOfImage" } },
                            { kind: "Field", name: { kind: "Name", value: "publisher" } },
                            { kind: "Field", name: { kind: "Name", value: "printRun" } },
                            { kind: "Field", name: { kind: "Name", value: "retailPrice" } },
                            { kind: "Field", name: { kind: "Name", value: "remark" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "artwork" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "extId" } },
                            { kind: "Field", name: { kind: "Name", value: "src" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metadata" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "creationDate" } },
                                  { kind: "Field", name: { kind: "Name", value: "creatorName" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assetFileFormat" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "importRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assets" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "size" } },
                                  { kind: "Field", name: { kind: "Name", value: "width" } },
                                  { kind: "Field", name: { kind: "Name", value: "height" } },
                                  { kind: "Field", name: { kind: "Name", value: "dpi" } },
                                  { kind: "Field", name: { kind: "Name", value: "fileSize" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "userTier" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginateMeta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "totalCount" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRequestByStateQuery, GetRequestByStateQueryVariables>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date scalar */
  Date: { input: any; output: any };
  RateCardOptionValue: { input: any; output: any };
};

export type AdminAddCollectionToUserInput = {
  collectionId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  orgId: Scalars["String"]["input"];
};

export type AdminCreateUserInput = {
  email: Scalars["String"]["input"];
  organizationProfile: OrganizationProfileInput;
  password: Scalars["String"]["input"];
  profile: ProfileInput;
};

export type AllFacet = {
  __typename?: "AllFacet";
  assetTypeFacet?: Maybe<Facet>;
  creationDateFacet?: Maybe<Facet>;
  creatorNameFacet?: Maybe<Facet>;
  exhibitionLoanHistoryFacet?: Maybe<Facet>;
  materialsAndTechnologyFacet?: Maybe<Facet>;
  orientationFacet?: Maybe<Facet>;
  relevantDatesFacet?: Maybe<Facet>;
  relevantGeosFacet?: Maybe<Facet>;
  seriesNameFacet?: Maybe<Facet>;
  copyrightOrRestrictionsFacet?: Maybe<Facet>;
};

export type Artwork = {
  __typename?: "Artwork";
  assets: Array<ArtworkAsset>;
  digitalTwin?: Maybe<ArtworkDigitalTwin>;
  extId: Scalars["String"]["output"];
  importRef?: Maybe<ArtworkImportRef>;
  importerVersion?: Maybe<Scalars["String"]["output"]>;
  licenseOptions?: Maybe<Array<Maybe<ArtworkLicenseOptions>>>;
  metadata?: Maybe<ArtworkMetadata>;
  orgId: Scalars["String"]["output"];
  pricesFrom: ArtworkPricesFrom;
  rateCard: RateCard;
  src: Scalars["String"]["output"];
};

export type ArtworkPricesFromArgs = {
  currency: Scalars["String"]["input"];
};

export type ArtworkRateCardArgs = {
  currency: Scalars["String"]["input"];
};

export type ArtworkAsset = {
  __typename?: "ArtworkAsset";
  dpi?: Maybe<Scalars["Int"]["output"]>;
  fileSize?: Maybe<Scalars["Int"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  size?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type ArtworkDigitalTwin = {
  __typename?: "ArtworkDigitalTwin";
  contractAddress?: Maybe<Scalars["String"]["output"]>;
  ipfsAddress?: Maybe<Scalars["String"]["output"]>;
  tokenID?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkDimension = {
  __typename?: "ArtworkDimension";
  depth?: Maybe<Scalars["Float"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  unit?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

export type ArtworkFilter = {
  path: Scalars["String"]["input"];
  query: Scalars["String"]["input"];
};

export type ArtworkImportRef = {
  __typename?: "ArtworkImportRef";
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkLicenseOptions = {
  __typename?: "ArtworkLicenseOptions";
  currency?: Maybe<Scalars["String"]["output"]>;
  defaultLicenseOption?: Maybe<Scalars["Boolean"]["output"]>;
  licenseMode?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  rateCard?: Maybe<ArtworkLicenseRateCard>;
};

export type ArtworkLicenseRateCard = {
  __typename?: "ArtworkLicenseRateCard";
  contentType?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["Float"]["output"]>;
  licenseType?: Maybe<Scalars["String"]["output"]>;
  resolution?: Maybe<Scalars["String"]["output"]>;
  territory?: Maybe<Scalars["String"]["output"]>;
  usage?: Maybe<Scalars["String"]["output"]>;
  use?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkMetadata = {
  __typename?: "ArtworkMetadata";
  assetFileFormat?: Maybe<Scalars["String"]["output"]>;
  assetFullText?: Maybe<Scalars["String"]["output"]>;
  assetType?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  assetURL?: Maybe<Scalars["String"]["output"]>;
  copyrightDocument?: Maybe<Scalars["String"]["output"]>;
  copyrightHolder?: Maybe<Scalars["String"]["output"]>;
  copyrightOrRestrictions?: Maybe<Scalars["String"]["output"]>;
  creationDate?: Maybe<Scalars["String"]["output"]>;
  creatorName?: Maybe<Scalars["String"]["output"]>;
  creditLine?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dimension?: Maybe<ArtworkDimension>;
  exhibitionLoanHistory?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  isPhysicalAsset?: Maybe<Scalars["Boolean"]["output"]>;
  materialsAndTechnology?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name: Scalars["String"]["output"];
  provenanceDocument?: Maybe<Scalars["String"]["output"]>;
  relevantDates?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  relevantGeos?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  seriesName?: Maybe<Scalars["String"]["output"]>;
};

export type ArtworkPricesFrom = {
  __typename?: "ArtworkPricesFrom";
  commercial?: Maybe<Scalars["Float"]["output"]>;
  custom?: Maybe<Scalars["Float"]["output"]>;
  editorial?: Maybe<Scalars["Float"]["output"]>;
  personal?: Maybe<Scalars["Float"]["output"]>;
};

export type ArtworkQueryExactFilters = {
  andFilter?: InputMaybe<Array<InputMaybe<ArtworkFilter>>>;
  filter?: InputMaybe<Array<InputMaybe<ArtworkFilter>>>;
  filters?: InputMaybe<Array<InputMaybe<Array<InputMaybe<ArtworkFilter>>>>>;
};

export type ArtworkQueryOptionsInput = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ArtworkSearchFacets = {
  __typename?: "ArtworkSearchFacets";
  count?: Maybe<Count>;
  facet?: Maybe<AllFacet>;
};

export type Auth = {
  __typename?: "Auth";
  email?: Maybe<Scalars["String"]["output"]>;
};

export type Bucket = {
  __typename?: "Bucket";
  _id?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export enum Checkout_Mode {
  DirectPurchase = "DIRECT_PURCHASE",
  RequestPurchase = "REQUEST_PURCHASE",
}

export type CheckoutFlow = {
  __typename?: "CheckoutFlow";
  mode: Checkout_Mode;
};

export type CheckoutOptions = {
  __typename?: "CheckoutOptions";
  stripe: StripeCheckoutOptions;
};

export type CheckoutSessionResponse = {
  __typename?: "CheckoutSessionResponse";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
};

export type Count = {
  __typename?: "Count";
  lowerBound?: Maybe<Scalars["Int"]["output"]>;
};

export type CreatePrefilledLicenseInput = {
  conditions: RequestConditionsInput;
  details: RequestDetailsInput;
  extId?: InputMaybe<Scalars["String"]["input"]>;
  makeDefault: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export type Entity = {
  __typename?: "Entity";
  addressLineOne?: Maybe<Scalars["String"]["output"]>;
  addressLineTwo?: Maybe<Scalars["String"]["output"]>;
  contactNumber?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  industry?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type EntityInput = {
  addressLineOne?: InputMaybe<Scalars["String"]["input"]>;
  addressLineTwo?: InputMaybe<Scalars["String"]["input"]>;
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  industry?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Facet = {
  __typename?: "Facet";
  buckets?: Maybe<Array<Maybe<Bucket>>>;
};

export type FeatureState = {
  __typename?: "FeatureState";
  checkoutFlow: CheckoutFlow;
  checkoutOptions: CheckoutOptions;
  licenseOptions: LicenseOptions;
  showLicenseTooltip: ShowLicenseTooltip;
};

export type GetArtworkAssetFromVaultResponse = {
  __typename?: "GetArtworkAssetFromVaultResponse";
  downloadUrl?: Maybe<Scalars["String"]["output"]>;
};

export type Invalid = {
  __typename?: "Invalid";
  reason: Scalars["String"]["output"];
  timestamp: Scalars["Date"]["output"];
};

export type License = {
  __typename?: "License";
  conditions: LicenseCondition;
  currency: Scalars["String"]["output"];
  details: LicenseDetails;
};

export type LicenseCondition = {
  __typename?: "LicenseCondition";
  contentType?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Float"]["output"];
  resolution: Scalars["String"]["output"];
  territory: Scalars["String"]["output"];
  usage: Scalars["String"]["output"];
  use: Scalars["String"]["output"];
};

export type LicenseConditionInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["Float"]["input"];
  licenseType: Scalars["String"]["input"];
  resolution: Scalars["String"]["input"];
  territory: Scalars["String"]["input"];
  usage: Scalars["String"]["input"];
  use: Scalars["String"]["input"];
};

export type LicenseDetails = {
  __typename?: "LicenseDetails";
  creator: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  formatOfReproduction: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  printRun?: Maybe<Scalars["String"]["output"]>;
  publisher: Scalars["String"]["output"];
  remark?: Maybe<Scalars["String"]["output"]>;
  retailPrice?: Maybe<Scalars["Float"]["output"]>;
  title: Scalars["String"]["output"];
  useOfImage: Scalars["String"]["output"];
};

export type LicenseDetailsInput = {
  creator: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  formatOfReproduction: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  printRun?: InputMaybe<Scalars["String"]["input"]>;
  publisher: Scalars["String"]["input"];
  remark?: InputMaybe<Scalars["String"]["input"]>;
  retailPrice?: InputMaybe<Scalars["Float"]["input"]>;
  title: Scalars["String"]["input"];
  useOfImage: Scalars["String"]["input"];
};

export type LicenseInput = {
  conditions: LicenseConditionInput;
  currency: Scalars["String"]["input"];
  details: LicenseDetailsInput;
};

export type LicenseOption = {
  __typename?: "LicenseOption";
  defualt?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  mode: Options_Mode;
  template?: Maybe<Scalars["String"]["output"]>;
};

export type LicenseOptions = {
  __typename?: "LicenseOptions";
  commercial: LicenseOption;
  custom: LicenseOption;
  editorial: LicenseOption;
  personal: LicenseOption;
};

export enum LimitedUpdateRequestStatus {
  Accepted = "ACCEPTED",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Revoked = "REVOKED",
}

export type Mutation = {
  __typename?: "Mutation";
  addCollectionToUser: Response;
  checkoutVault: Array<RequestCreation>;
  completeMultiRequestsProcessing?: Maybe<Array<Maybe<Request>>>;
  completeStraightThroughRequestByExtId?: Maybe<Request>;
  createCheckoutSession?: Maybe<CheckoutSessionResponse>;
  createRequest: RequestCreation;
  createUser: Response;
  createVault?: Maybe<Vault>;
  createVaultItem?: Maybe<Vault>;
  deletePrefilledLicense?: Maybe<Scalars["Boolean"]["output"]>;
  prefilledLicense?: Maybe<PrefilledLicense>;
  removeVaultItem?: Maybe<Vault>;
  updateRequestsStatus?: Maybe<RequestUpdate>;
  updateVaultItem?: Maybe<Vault>;
  user?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MutationAddCollectionToUserArgs = {
  input: AdminAddCollectionToUserInput;
};

export type MutationCheckoutVaultArgs = {
  vaultItemExtIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MutationCompleteMultiRequestsProcessingArgs = {
  extIds: Array<Scalars["String"]["input"]>;
};

export type MutationCompleteStraightThroughRequestByExtIdArgs = {
  extId: Scalars["String"]["input"];
};

export type MutationCreateCheckoutSessionArgs = {
  currency: Scalars["String"]["input"];
  extIds: Array<InputMaybe<Scalars["String"]["input"]>>;
  orderKey: Scalars["String"]["input"];
  unitAmount: Scalars["Int"]["input"];
};

export type MutationCreateRequestArgs = {
  artwork: Scalars["String"]["input"];
  currency: Scalars["String"]["input"];
  request: RequestInput;
};

export type MutationCreateUserArgs = {
  input: AdminCreateUserInput;
};

export type MutationCreateVaultItemArgs = {
  item?: InputMaybe<VaultItemInput>;
  withCreatePrefilledLicense?: InputMaybe<WithCreatePrefilledLicenseInput>;
};

export type MutationDeletePrefilledLicenseArgs = {
  extId: Scalars["String"]["input"];
};

export type MutationPrefilledLicenseArgs = {
  input?: InputMaybe<CreatePrefilledLicenseInput>;
};

export type MutationRemoveVaultItemArgs = {
  extId: Scalars["String"]["input"];
};

export type MutationUpdateRequestsStatusArgs = {
  requestStatus: LimitedUpdateRequestStatus;
  requests: Array<RequestWithVersionInput>;
};

export type MutationUpdateVaultItemArgs = {
  extId?: InputMaybe<Scalars["String"]["input"]>;
  item?: InputMaybe<VaultItemInput>;
  withCreatePrefilledLicense?: InputMaybe<WithCreatePrefilledLicenseInput>;
};

export type MutationUserArgs = {
  input?: InputMaybe<UserCreateUserInput>;
};

export enum Options_Mode {
  Email = "EMAIL",
  Purchase = "PURCHASE",
  Request = "REQUEST",
}

export type OrganizationProfileInput = {
  isAdmin: Scalars["Boolean"]["input"];
  orgId: Scalars["String"]["input"];
};

export type PaginatedArtwork = {
  __typename?: "PaginatedArtwork";
  paginateMeta?: Maybe<PaginatedMetadata>;
  paginatedResults?: Maybe<Array<Maybe<Artwork>>>;
};

export type PaginatedMetadata = {
  __typename?: "PaginatedMetadata";
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type PaginatedPrefilledLicenses = {
  __typename?: "PaginatedPrefilledLicenses";
  paginateMeta: PaginatedMetadata;
  paginatedResults: Array<PrefilledLicense>;
};

export type PaginatedRequests = {
  __typename?: "PaginatedRequests";
  paginateMeta: PaginatedMetadata;
  paginatedResults: Array<Request>;
};

export type PrefilledLicense = {
  __typename?: "PrefilledLicense";
  conditions: RequestConditions;
  createdAt: Scalars["Date"]["output"];
  details: RequestDetails;
  extId: Scalars["String"]["output"];
  isDefault: Scalars["Boolean"]["output"];
  orgId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PricingCondition = {
  __typename?: "PricingCondition";
  contentType?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Float"]["output"];
  resolution: Scalars["String"]["output"];
  territory: Scalars["String"]["output"];
  usage: Scalars["String"]["output"];
  use: Scalars["String"]["output"];
};

export type PricingConditionInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  licenseType?: InputMaybe<Scalars["String"]["input"]>;
  resolution?: InputMaybe<Scalars["String"]["input"]>;
  territory?: InputMaybe<Scalars["String"]["input"]>;
  usage?: InputMaybe<Scalars["String"]["input"]>;
  use?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProfileInput = {
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  entity?: InputMaybe<EntityInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type PublicUserProfile = {
  __typename?: "PublicUserProfile";
  auth?: Maybe<Auth>;
  extId?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<UserProfile>;
};

export type Query = {
  __typename?: "Query";
  artwork?: Maybe<Artwork>;
  artworks?: Maybe<PaginatedArtwork>;
  completedRequests: Array<Maybe<Request>>;
  featureFlags: FeatureState;
  getArtworkAssetFromVault?: Maybe<GetArtworkAssetFromVaultResponse>;
  getArtworkAutocompleteResults?: Maybe<Array<Maybe<Artwork>>>;
  getCheckoutSession?: Maybe<SessionResponse>;
  getSearchFacets?: Maybe<ArtworkSearchFacets>;
  hello?: Maybe<Response>;
  prefilledLicenses: PaginatedPrefilledLicenses;
  rateCard?: Maybe<RateCard>;
  rateCardFieldsAndPrices?: Maybe<RateCardFieldsAndPricesResponse>;
  request?: Maybe<Request>;
  requestByUser?: Maybe<Request>;
  requests?: Maybe<PaginatedRequests>;
  requestsByUser?: Maybe<PaginatedRequests>;
  searchArtworks?: Maybe<PaginatedArtwork>;
  user?: Maybe<User>;
  vault?: Maybe<Vault>;
  vaultItem?: Maybe<VaultItem>;
};

export type QueryArtworkArgs = {
  extId: Scalars["String"]["input"];
};

export type QueryCompletedRequestsArgs = {
  expired: Scalars["Boolean"]["input"];
};

export type QueryGetArtworkAssetFromVaultArgs = {
  requestExtId: Scalars["String"]["input"];
};

export type QueryGetArtworkAutocompleteResultsArgs = {
  options?: InputMaybe<ArtworkQueryOptionsInput>;
  query: Scalars["String"]["input"];
};

export type QueryGetCheckoutSessionArgs = {
  sessionId: Scalars["String"]["input"];
};

export type QueryGetSearchFacetsArgs = {
  query: Scalars["String"]["input"];
};

export type QueryRateCardArgs = {
  orgId: Scalars["String"]["input"];
};

export type QueryRateCardFieldsAndPricesArgs = {
  conditions?: InputMaybe<PricingConditionInput>;
  orgId: Scalars["String"]["input"];
  queryFor: Scalars["String"]["input"];
};

export type QueryRequestArgs = {
  extId: Scalars["String"]["input"];
};

export type QueryRequestByUserArgs = {
  extId: Scalars["String"]["input"];
};

export type QueryRequestsArgs = {
  options?: InputMaybe<RequestsQueryOptionsInput>;
  status: Scalars["String"]["input"];
};

export type QueryRequestsByUserArgs = {
  options?: InputMaybe<RequestsQueryOptionsInput>;
  status?: InputMaybe<RequestStatus>;
};

export type QuerySearchArtworksArgs = {
  exactFilters?: InputMaybe<ArtworkQueryExactFilters>;
  keyword?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<ArtworkQueryOptionsInput>;
};

export type QueryVaultItemArgs = {
  extId: Scalars["String"]["input"];
};

export type RateCard = {
  __typename?: "RateCard";
  currency: Scalars["String"]["output"];
  extId?: Maybe<Scalars["String"]["output"]>;
  rates: Array<Maybe<RateCardRule>>;
};

export type RateCardFieldsAndPricesResponse = {
  __typename?: "RateCardFieldsAndPricesResponse";
  foundPrice?: Maybe<Scalars["Int"]["output"]>;
  matchOptions?: Maybe<Array<Maybe<RateCardOptions>>>;
  maxPrice?: Maybe<Scalars["Int"]["output"]>;
  minPrice?: Maybe<Scalars["Int"]["output"]>;
};

export type RateCardOptions = {
  __typename?: "RateCardOptions";
  label?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["RateCardOptionValue"]["output"]>;
};

export type RateCardRule = {
  __typename?: "RateCardRule";
  conditions: PricingCondition;
  price: Scalars["Float"]["output"];
};

export type Request = {
  __typename?: "Request";
  artwork?: Maybe<Artwork>;
  conditions?: Maybe<RequestConditions>;
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  creatorProfile?: Maybe<UserProfile>;
  currency?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<RequestDetails>;
  expiresAt?: Maybe<Scalars["Date"]["output"]>;
  extId?: Maybe<Scalars["String"]["output"]>;
  isPaid: Scalars["Boolean"]["output"];
  price?: Maybe<Scalars["Float"]["output"]>;
  publicUserProfile?: Maybe<PublicUserProfile>;
  status?: Maybe<RequestStatus>;
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type RequestConditions = {
  __typename?: "RequestConditions";
  duration: Scalars["Float"]["output"];
  resolution: Scalars["String"]["output"];
  territory: Scalars["String"]["output"];
  usage: Scalars["String"]["output"];
  use: Scalars["String"]["output"];
};

export type RequestConditionsInput = {
  duration: Scalars["Float"]["input"];
  licenseType?: InputMaybe<Scalars["String"]["input"]>;
  resolution: Scalars["String"]["input"];
  territory: Scalars["String"]["input"];
  usage: Scalars["String"]["input"];
  use: Scalars["String"]["input"];
};

export type RequestCreatePrefilledLicenseInput = {
  create: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export type RequestCreation = {
  __typename?: "RequestCreation";
  extId: Scalars["String"]["output"];
  status: RequestStatus;
};

export type RequestDetails = {
  __typename?: "RequestDetails";
  creator: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  formatOfReproduction: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  printRun?: Maybe<Scalars["String"]["output"]>;
  publisher: Scalars["String"]["output"];
  remark?: Maybe<Scalars["String"]["output"]>;
  retailPrice?: Maybe<Scalars["Float"]["output"]>;
  title: Scalars["String"]["output"];
  useOfImage: Scalars["String"]["output"];
};

export type RequestDetailsInput = {
  creator: Scalars["String"]["input"];
  formatOfReproduction: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  printRun?: InputMaybe<Scalars["String"]["input"]>;
  publisher: Scalars["String"]["input"];
  remark?: InputMaybe<Scalars["String"]["input"]>;
  retailPrice?: InputMaybe<Scalars["Float"]["input"]>;
  title: Scalars["String"]["input"];
  useOfImage: Scalars["String"]["input"];
};

export type RequestInput = {
  conditions: RequestConditionsInput;
  details: RequestDetailsInput;
  prefilledLicense?: InputMaybe<RequestCreatePrefilledLicenseInput>;
};

export enum RequestStatus {
  Accepted = "ACCEPTED",
  Approved = "APPROVED",
  Completed = "COMPLETED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Revoked = "REVOKED",
}

export type RequestUpdate = {
  __typename?: "RequestUpdate";
  requests?: Maybe<Array<Maybe<Request>>>;
  success: Scalars["Boolean"]["output"];
};

export type RequestWithVersionInput = {
  extId: Scalars["String"]["input"];
  version: Scalars["Int"]["input"];
};

export type RequestsQueryOptionsInput = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Response = {
  __typename?: "Response";
  success: Scalars["String"]["output"];
};

export type SessionResponse = {
  __typename?: "SessionResponse";
  customerEmail?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type ShowLicenseTooltip = {
  __typename?: "ShowLicenseTooltip";
  enabled: Scalars["Boolean"]["output"];
};

export type StripeCheckoutOptions = {
  __typename?: "StripeCheckoutOptions";
  enabled: Scalars["Boolean"]["output"];
  publishableKey?: Maybe<Scalars["String"]["output"]>;
};

export type User = {
  __typename?: "User";
  isAdmin?: Maybe<Scalars["Boolean"]["output"]>;
  orgId?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<UserProfile>;
};

export type UserCreateUserInput = {
  profile: UserProfileInput;
};

export type UserEntityMetadata = {
  __typename?: "UserEntityMetadata";
  addressLineOne?: Maybe<Scalars["String"]["output"]>;
  addressLineTwo?: Maybe<Scalars["String"]["output"]>;
  contactNumber?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  industry?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type UserEntityMetadataInput = {
  addressLineOne?: InputMaybe<Scalars["String"]["input"]>;
  addressLineTwo?: InputMaybe<Scalars["String"]["input"]>;
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  industry?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  contactNumber?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<UserEntityMetadata>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  userTier?: Maybe<UserTier>;
};

export type UserProfileInput = {
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  entity?: InputMaybe<UserEntityMetadataInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserTier {
  AllAccess = "ALL_ACCESS",
  Basic = "BASIC",
}

export type Vault = {
  __typename?: "Vault";
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  items: Array<Maybe<VaultItem>>;
  orgId: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
  userId: Scalars["String"]["output"];
};

export type VaultItem = {
  __typename?: "VaultItem";
  artwork?: Maybe<Artwork>;
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  extId: Scalars["String"]["output"];
  invalid?: Maybe<Invalid>;
  itemDetails: License;
  price: Scalars["Float"]["output"];
  refId: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type VaultItemInput = {
  itemDetails: LicenseInput;
  refId: Scalars["String"]["input"];
};

export type WithCreatePrefilledLicenseInput = {
  create: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export enum AuthProvider {
  Auth0 = "AUTH0",
  Custom = "CUSTOM",
}

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; isAdmin?: boolean | null } | null;
};

export type GetRequestDetailsQueryVariables = Exact<{
  requestExtId: Scalars["String"]["input"];
}>;

export type GetRequestDetailsQuery = {
  __typename?: "Query";
  request?: {
    __typename?: "Request";
    extId?: string | null;
    version: number;
    status?: RequestStatus | null;
    price?: number | null;
    artwork?: {
      __typename?: "Artwork";
      extId: string;
      src: string;
      assets: Array<{
        __typename?: "ArtworkAsset";
        type?: string | null;
        size?: string | null;
        width?: number | null;
        height?: number | null;
        dpi?: number | null;
        fileSize?: number | null;
      }>;
      metadata?: {
        __typename?: "ArtworkMetadata";
        name: string;
        creationDate?: string | null;
        creatorName?: string | null;
        copyrightOrRestrictions?: string | null;
        materialsAndTechnology?: Array<string | null> | null;
        seriesName?: string | null;
        relevantDates?: Array<string | null> | null;
        relevantGeos?: Array<string | null> | null;
      } | null;
      importRef?: { __typename?: "ArtworkImportRef"; value?: string | null } | null;
    } | null;
    conditions?: {
      __typename?: "RequestConditions";
      use: string;
      territory: string;
      usage: string;
      duration: number;
      resolution: string;
    } | null;
    details?: {
      __typename?: "RequestDetails";
      title: string;
      creator: string;
      formatOfReproduction: string;
      language: string;
      useOfImage: string;
      publisher: string;
      printRun?: string | null;
      retailPrice?: number | null;
      remark?: string | null;
    } | null;
    publicUserProfile?: {
      __typename?: "PublicUserProfile";
      auth?: { __typename?: "Auth"; email?: string | null } | null;
    } | null;
    creatorProfile?: {
      __typename?: "UserProfile";
      firstName?: string | null;
      lastName?: string | null;
      contactNumber?: string | null;
      userTier?: UserTier | null;
      entity?: {
        __typename?: "UserEntityMetadata";
        name?: string | null;
        jobTitle?: string | null;
        industry?: string | null;
        country?: string | null;
        addressLineOne?: string | null;
        addressLineTwo?: string | null;
        contactNumber?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateRequestsStatusMutationVariables = Exact<{
  requests: Array<RequestWithVersionInput> | RequestWithVersionInput;
  requestStatus: LimitedUpdateRequestStatus;
}>;

export type UpdateRequestsStatusMutation = {
  __typename?: "Mutation";
  updateRequestsStatus?: { __typename?: "RequestUpdate"; success: boolean } | null;
};

export type GetRequestByStateQueryVariables = Exact<{
  status: Scalars["String"]["input"];
  options?: InputMaybe<RequestsQueryOptionsInput>;
}>;

export type GetRequestByStateQuery = {
  __typename?: "Query";
  requests?: {
    __typename?: "PaginatedRequests";
    paginatedResults: Array<{
      __typename?: "Request";
      extId?: string | null;
      version: number;
      price?: number | null;
      createdAt?: any | null;
      conditions?: {
        __typename?: "RequestConditions";
        use: string;
        territory: string;
        usage: string;
        duration: number;
        resolution: string;
      } | null;
      details?: {
        __typename?: "RequestDetails";
        title: string;
        creator: string;
        formatOfReproduction: string;
        language: string;
        useOfImage: string;
        publisher: string;
        printRun?: string | null;
        retailPrice?: number | null;
        remark?: string | null;
      } | null;
      artwork?: {
        __typename?: "Artwork";
        extId: string;
        src: string;
        metadata?: {
          __typename?: "ArtworkMetadata";
          name: string;
          creationDate?: string | null;
          creatorName?: string | null;
          assetFileFormat?: string | null;
        } | null;
        importRef?: {
          __typename?: "ArtworkImportRef";
          name?: string | null;
          value?: string | null;
        } | null;
        assets: Array<{
          __typename?: "ArtworkAsset";
          size?: string | null;
          width?: number | null;
          height?: number | null;
          dpi?: number | null;
          fileSize?: number | null;
        }>;
      } | null;
      creatorProfile?: {
        __typename?: "UserProfile";
        firstName?: string | null;
        lastName?: string | null;
        userTier?: UserTier | null;
        entity?: { __typename?: "UserEntityMetadata"; name?: string | null } | null;
      } | null;
    }>;
    paginateMeta: { __typename?: "PaginatedMetadata"; totalCount?: number | null };
  } | null;
};
