const SuccessTick = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="white" height="64" rx="32" width="64" />
      <path
        d="M64 32C64 14.3392 49.6608 0 32 0C14.3392 0 0 14.3392 0 32C0 49.6608 14.3392 64 32 64C49.6608 64 64 49.6608 64 32ZM47.6644 22.418L29.965 45.8205C29.42 46.5411 28.5916 46.9927 27.6906 47.0602C26.7897 47.1278 25.9031 46.8047 25.2568 46.1734L16.8004 37.9137C15.5573 36.6995 15.5338 34.7073 16.7481 33.4641C17.9624 32.221 19.9545 32.1975 21.1977 33.4118L27.0997 39.1765L42.6451 18.6219C43.6934 17.2359 45.6668 16.9621 47.0528 18.0104C48.4388 19.0586 48.7127 21.032 47.6644 22.418Z"
        fill="#45BE76"
      />
    </svg>
  );
};

export default SuccessTick;
