import { clsMerge } from "@artifactlabs/shared-react-utils";
import { ButtonHTMLAttributes, FunctionComponent } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "contained" | "outlined";
}

const AppButton: FunctionComponent<ButtonProps> = ({ variant, className, children, ...rest }) => {
  const baseButtonClasses = clsMerge(
    "flex justify-center items-center ",
    "h-[40px] px-4 rounded-[4px]",
    "text-center text-white text-sm font-medium font-poppins leading-tight",
    "disabled:bg-[#B4B4B4] disabled:text-white",
  );
  const containedButtonClasses = "bg-[#1C2442] hover:bg-[#35447d]";
  const outlinedButtonClasses = "ring-[#1C2442] ring-1 text-[#1C2442]";

  return (
    <button
      type="button"
      {...rest}
      className={clsMerge(
        baseButtonClasses,
        variant === "contained" && containedButtonClasses,
        variant === "outlined" && outlinedButtonClasses,
        className,
      )}
    >
      {children}
    </button>
  );
};

export default AppButton;
