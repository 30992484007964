//
import { ASSET_CONTENT_TYPE } from "@/enum/assetContentType";
export const getAssetContentTypeWithAssetFileFormat = (assetFileFormat: string = ""): string => {
  const videoFormats = [
    "video/mp4",
    "video/quicktime",
    "video/mov",
    "video/avi",
    "video/mpeg",
    "video/3gpp",
    "video/3gpp2",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/x-flv",
    "video/x-matroska",
    "video/webm",
    "video/ogg",
    "video/x-ms-asf",
    "video/x-ms-wmv",
    "video/x-ms-wmx",
    "video/x-ms-wvx",
  ];

  const audioFormats = [
    "audio/mp3",
    "audio/mpeg",
    "audio/mp4",
    "audio/x-m4a",
    "audio/x-wav",
    "audio/x-aiff",
    "audio/x-flac",
    "audio/x-matroska",
    "audio/ogg",
    "audio/x-ms-wma",
    "audio/x-ms-wax",
    "audio/x-ms-wvx",
    "audio/x-ms-wmx",
    "audio/x-ms-asf",
    "audio/x-ms-wmv",
  ];

  if (audioFormats.includes((assetFileFormat ?? "").toLowerCase())) {
    return ASSET_CONTENT_TYPE.AUDIO;
  } else if (videoFormats.includes((assetFileFormat ?? "").toLowerCase())) {
    return ASSET_CONTENT_TYPE.VIDEO;
  }
  return ASSET_CONTENT_TYPE.IMAGE;
};
